<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import draggable from "vuedraggable";
export default {
  name: "BoInstitution",
  extends: Base,
  components: {
    draggable,
  },
  data() {
    return {
      Name: "BoInstitution",
      data: [],
      input: {},
      ObjectName: "Institution",
      isEdit: false,
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData();
  },
  methods: {
    editLembaga(v) {
      this.isEdit = true;
      $("label.error").css("display","none");
      $("[type='submit']").html('Ubah');      
      let disp = $("#collapseLembaga" + v.ai_id).css("display")
      if(!disp){
        $(".mt-5.collapse").css("display","block")
      }
      $("#collapseLembaga" + v.ai_id).slideToggle();

      setTimeout(()=>{
        $("html, body").animate(
          {
            scrollTop: $("#collapseLembaga" + v.ai_id).offset().top - 100,
          },
          500
        );
      },100)
      this.input = v;
      this.input.type = "update";
    },
    endDragNilai() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data,
          type: "sortNilai",
        },
        (err, resp) => {
          this.refreshData()
        },
        "POST"
      );
    },
    addLembaga() {
      this.isEdit = false;
      $("label.error").css("display","none");
      $("[type='submit']").html('Tambah');      
      let disp = $("#collapseLembaga").css("display")
      if(!disp){
        $(".mt-5.collapse").css("display","block")
      }
      $("#collapseLembaga").slideToggle();
      
      setTimeout(()=>{
        $("html, body").animate(
          {
            scrollTop: $("#collapseLembaga").offset().top,
          },
          500
        );
      },100)
      this.input = {};
      this.input.type = "add";
      this.input.ai_is_active = "Y";
    },
    submitLembaga(e) {
      if (e && e.btnLoading()) return;

      if(this.input.ai_img){
        $("[error='ai_img']").html("");
      }

      BOGen.apirest(
        "/" + this.Name,
        this.input,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("[type='submit']").html(this.isEdit ? "Ubah" : "Tambah");      
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".info");
            this.refreshData();
            setTimeout(() => {
              $(".mt-5.collapse").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.query"() {
      this.refreshData();
    },
    "row.as_title"(v) {
      this.slTitleLength = v.length;
    },
    "input.ai_name"(v) {
      this.input.ai_alt_img = v;
      setTimeout(() => {
        $("[name='ai_name']").valid();
        $("[name='ai_alt_img']").valid();
      }, 100);
    },
    "input.ai_url"(v) {
      setTimeout(() => {
        $("[name='ai_url']").valid();
      }, 100);
    }
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Lembaga</h5>
          </div>
          <div class="card-body">
            <draggable v-if="moduleRole('Edit')" v-model="data" class="row gutter-20" @end="endDragNilai">
              <div
                style="cursor:move;"
                class="col-md-3 col-lg-20"
                v-for="(v, k) in data"
                :id="'dat' + v.ai_id"
                :key="k"
              >
                <div class="wrap_slider_img" style="background-color: #ececec;">
                  <img
                    :src="uploader(v.ai_img)"
                    class="img-responsive"
                    width="100%"
                  />
                  <div class="slider_name">
                    <p>{{ v.ai_name }}</p>
                  </div>
                  <div class="bullet-cta">
                    <a
                      v-if="moduleRole('Edit')"
                      data-toggle="collapse"
                      @click="editLembaga(v)"
                      href="javascript:;"
                      class="bullet_edit"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></a>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="bullet_change_status bg-warning"
                      @click="changeStatusDynamic(v, 'ai_is_active', 'ai_id', undefined, undefined, `collapseLembaga${isEdit ? v.ai_id : ''}`)"
                      v-tooltip="'Ubah Status'"
                      ><i class="ti-settings"></i
                    ></a>
                    <a
                      v-if="moduleRole('Delete')"
                      href="javascript:;"
                      @click="deleteItemId(v.ai_id, 'dat' + v.ai_id, `collapseLembaga${isEdit ? v.ai_id : ''}`)"
                      class="bullet_delete"
                      v-tooltip="'Hapus'"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>

                  <span class="label label-success" v-if="v.ai_is_active == 'Y'"
                    >Active</span
                  >
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div
                class="col-md-3 col-lg-20"
                :class="data.length > 3 ? 'mt-3' : ''"
              >
                <a
                  v-if="moduleRole('Add')"
                  class="wrap_upload"
                  data-toggle="collapse"
                  @click="addLembaga"
                  href="#collapseLembaga"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseLembaga"
                >
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>Tambah Lembaga</p>
                  </div>
                </a>
              </div>
            </draggable>
            <div v-else class="row gutter-20">
              <div
                class="col-md-3 col-lg-20"
                v-for="(v, k) in data"
                :id="'dat' + v.ai_id"
                :key="k"
              >
                <div class="wrap_slider_img" style="background-color: #ececec;">
                  <img
                    :src="uploader(v.ai_img)"
                    class="img-responsive"
                    width="100%"
                  />
                  <div class="slider_name">
                    <p>{{ v.ai_name }}</p>
                  </div>
                  <div class="bullet-cta">
                    <a
                      v-if="moduleRole('Edit')"
                      data-toggle="collapse"
                      @click="editLembaga(v)"
                      href="javascript:;"
                      class="bullet_edit"
                      v-tooltip="'Uba'"
                      ><i class="ti-marker-alt"></i
                    ></a>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="bullet_change_status bg-warning"
                      @click="changeStatusDynamic(v, 'ai_is_active', 'ai_id')"
                      v-tooltip="'Ubah Status'"
                      ><i class="ti-settings"></i
                    ></a>
                    <a
                      v-if="moduleRole('Delete')"
                      href="javascript:;"
                      @click="deleteItemId(v.ai_id, 'dat' + v.ai_id, 'collapseLembaga'+ v.ai_id)"
                      class="bullet_delete"
                      v-tooltip="'Hapus'"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>

                  <span class="label label-success" v-if="v.ai_is_active == 'Y'"
                    >Active</span
                  >
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div
                class="col-md-3 col-lg-20"
                :class="data.length > 3 ? 'mt-3' : ''"
              >
                <a
                  v-if="moduleRole('Add')"
                  class="wrap_upload"
                  data-toggle="collapse"
                  @click="addLembaga"
                  href="#collapseLembaga"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseLembaga"
                >
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>Add New</p>
                  </div>
                </a>
              </div>
            </div>
            <div v-if="isEdit ? moduleRole('Edit') : moduleRole('Add')" class="row collapse mt-5" :id="isEdit ? 'collapseLembaga' + input.ai_id : 'collapseLembaga'">
              <div class="col-md-12">
                <VForm @resp="submitLembaga" method="post">
                  <div class="card border">
                    <div class="card-header">
                      <h5 class="card-title">
                        {{ (input.ai_id ? "Ubah" : "Tambah") + " " + ObjectName }}
                      </h5>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-4 mb-3">
                          <BoField name="ai_img" :label="'Image'" class="mb-0" mandatory>
                            <Uploader
                              name="ai_img"
                              :param="{ thumbnail: true }"
                              type="institution"
                              :deleted="false"
                              uploadType="cropping"
                              v-model="input.ai_img"
                              :bgGrey="false"
                            ></Uploader>
                          </BoField>
                          <BoField
                            mandatory
                            name="ai_alt_img"
                            v-model="input.ai_alt_img"
                            :attr="{
                              type: 'text',
                              placeholder: 'e.g. Perpustakaan Nasional'
                            }"
                            :label="'Alt Image'"
                            v-bind="validation('ai_alt_img')"
                          ></BoField>
                        </div>
                        <div class="col-md-8 mb-3">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="panel_group">
                                <div class="panel_heading">Lembaga</div>
                                <div class="panel_body">
                                  <div class="row">
                                    <div class="col-12 info"></div>
                                    <div class="col-md-6">
                                      <BoField
                                        name="ai_name"
                                        :label="'Nama Lembaga'"
                                        :attr="{
                                          maxlength: '100',
                                          minlength: '3',
                                          required: 'required',
                                          placeholder: 'e.g. Perpustakaan Nasional'
                                        }"
                                        v-model="input.ai_name"
                                        v-bind="validation('ai_name')"
                                        mandatory
                                      ></BoField>
                                    </div>
                                    <div class="col-md-12">
                                      <BoField
                                        name="ai_url"
                                        :label="'Link Website Lembaga'"
                                        v-model="input.ai_url"
                                        :attr="{
                                          placeholder: 'e.g. https://www.perpusnas.go.id/'  
                                        }"
                                        v-bind="validation('ai_url')"
                                      ></BoField>
                                    </div>
                                    <div class="col-md-12">
                                      <BoField
                                        name="ai_is_active"
                                        :label="'Status'"
                                      >
                                        <radio
                                          v-model="input.ai_is_active"
                                          option="Y"
                                          :attr="validation('ai_is_active')"
                                          >Active</radio
                                        >
                                        <radio
                                          v-model="input.ai_is_active"
                                          option="N"
                                          >Inactive</radio
                                        >
                                      </BoField>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-3 text-right">
                          <button
                            type="submit"
                            class="btn btn-rounded btn-loading btn-info"
                          >
                            {{isEdit ? 'Ubah' : 'Tambah'}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </VForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
